import { configureStore } from '@reduxjs/toolkit';
import usernameSlice from './usernameSlice';
import balanceSlice from './balanceSlice';
import rateSlice from './rateSlice';
import progressSlice from './progressSlice';
import searchQuerySlice from './searchQuerySlice';
import sortTypeSlice from './sortTypeSlice';
import sortByFieldSlice from './sortByFieldSlice';
import updateFileListSlice from './updateFileListSlice';
import updateFolderListSlice from './updateFolderListSlice';
import uploadFolderSlice from './uploadFolderSlice';
import widgetFileSlice from './widgetFileSlice';
import requestTimeoutSlice from './requestTimeoutSlice';
import handleSeekSlice from './handleSeekSlice';
import fileManagerSlice from './fileManagerSlice';
import fileScrollSlice from './fileScrollSlice';
import languageOptionSlice from './languageOptionSlice';
import newFolderNameSlice from './newFolderNameSlice';
import updateBalanceSlice from './updateBalanceSlice';
import uploadProcessSlice from './uploadProcessSlice';
import balanceErrorSlice from './balanceErrorSlice';

export const store = configureStore({
    reducer: {
        username: usernameSlice,
        balance: balanceSlice,
        rate: rateSlice,
        progress: progressSlice,
        searchQuery: searchQuerySlice,
        sortType: sortTypeSlice,
        sortByField: sortByFieldSlice,
        updateFileList: updateFileListSlice,
        updateFolderList: updateFolderListSlice, 
        uploadFolder: uploadFolderSlice,
        widgetFile: widgetFileSlice,
        requestTimeout: requestTimeoutSlice,
        handleSeek: handleSeekSlice,
        fileManager: fileManagerSlice,
        fileScroll: fileScrollSlice,
        languageOption: languageOptionSlice,
        newFolderName: newFolderNameSlice,
        updateBalance: updateBalanceSlice,
        uploadProcess: uploadProcessSlice,
        balanceError: balanceErrorSlice
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;